import React from "react";
import TSTable from "../common/TSTable";
import { URL_DASHBOARD, URL_INFLORESCENCE, URL_PALMTREE } from "../../constants/urls";
import { DatePicker, Typography, Table, Select } from "antd";
import dayjs from 'dayjs';
import { GET } from "../../frameworks/HttpClient";
import useDropdownItem from "../../hooks/useDropdownItem";

export default function EmployeePerformanceTable(props) {  
  const { Text } = Typography;
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [selectGender, setSelectGender] = React.useState(null);
  const [selectPlantation, setSelectPlantation] = React.useState(null);
  const [genderOptions, setGenderOptions] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const tableRef = React.useRef(null);

  const plantationDropdown = useDropdownItem({
    url: URL_PALMTREE.PLANTATION,
    label: 'code',
    value: 'id',
    pagination: true,
    // params: { worker: _.get(user, 'id', null), page_size: 999 }
  })

  const fetchGenderOptions = async () => {
    setErrorMessages(null)
    try {
      const response = await GET(URL_INFLORESCENCE.GENDER, { page_size: 999 })
      setGenderOptions(response.data.map((item) => { 
          return { ...item, label: item[1], value: item[0] }
        })
      )
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const columns = [
    {
      title: "พนักงาน",
      dataIndex: "submitter_name",
    },
    {
      title: "จำนวนช่อ",
      children: [
        {
            title: <center>สำรวจ (ช่อ)</center>,
            dataIndex: "create_count",
        },
        {
            title: <center>ช่อคลุม (ช่อ)</center>,
            dataIndex: "cover_count",
        },
        {
            title: <center>ช่อผสม (ช่อ)</center>,
            dataIndex: "pollinate_count",
        },
        {
            title: <center>ตัดทะลาย (ทะลาย)</center>,
            dataIndex: "cut_count",
        },
        {
          title: <center>ช่อเสียหาย(ช่อ)</center>,
          dataIndex: "reject_count",
      },
      ]
    },
  ]

  const renderSummary = (e) => {
    // return (
    //   <Table.Summary fixed>
    //     <Table.Summary.Row >
    //       <Table.Summary.Cell index={0}>รวม</Table.Summary.Cell>
    //       <Table.Summary.Cell index={1}>{e.create_count}</Table.Summary.Cell>
    //       <Table.Summary.Cell index={2}>{}</Table.Summary.Cell>
    //       <Table.Summary.Cell index={3}>{}</Table.Summary.Cell>
    //       <Table.Summary.Cell index={4}>{}</Table.Summary.Cell>
    //       <Table.Summary.Cell index={5}>{}</Table.Summary.Cell>
    //     </Table.Summary.Row>
    //   </Table.Summary>
    // )
  }

  const onChange = (date, _) => {
    setSelectedDate(date)
  };

  React.useEffect(() => {
    if (tableRef) {
      tableRef.current.fetch();
    }
  }, [selectedDate])

  React.useEffect(() => {
    fetchGenderOptions();
    plantationDropdown.fetchOptions()
  },[])

  React.useEffect(() => {
    if (tableRef) {
      tableRef.current.fetch();
    }
  },[selectGender])

  React.useEffect(() => {
    if (tableRef) {
      tableRef.current.fetch();
    }
  },[selectPlantation])

  return (
    <div>
      <div style={{ marginBottom : 8}}>
        <Text>เดือน : </Text>
        <DatePicker 
          onChange={onChange} 
          picker="month" 
          placeholder={"เลือก..."} 
          allowClear={false}
          defaultValue={dayjs()}/>
        <Text style= {{ marginLeft : 20}}>เพศ : </Text>
        <Select
          style={{ width : 200}}
          initialvalues={'-'}
          autoComplete='off'
          placeholder='เลือกเพศ...'                                                    
          filterOption={filterOption}
          options={genderOptions}
          onChange={(value) => setSelectGender(value)}
          allowClear={true}
          onClear={() => setSelectGender('')}
        />
        <Text style={{ marginLeft : 20}}>สวน : </Text>
        <Select
            style= {{ width : 200}}
            showSearch
            autoComplete='off'
            optionFilterProp='code'
            placeholder='เลือก...' 
            options={plantationDropdown.options}
            loading={plantationDropdown.loading}
            onChange={(value) => setSelectPlantation(value)}
            allowClear={true}
            onClear={() => setSelectPlantation('')}/>
      </div>

      <TSTable
        ref={tableRef}
        columns={columns}
        rowKey={"id"}
        url={URL_DASHBOARD.WORKER_DASHBOARD}
        params={{
          ordering: "submitter_name", 
          month: selectedDate ? selectedDate.format("M") : null, 
          year: selectedDate ? selectedDate.format("YYYY") : null,
          gender: selectGender,
          plantation : selectPlantation
        }}
        size={"small"}
        hidePagination={true}
        pageSize={100}
        summary={(e) => renderSummary(e)}
      />
    </div>
  )
}